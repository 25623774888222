<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h4 class="text-center">
              {{ $t('register_line') }}
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <appointment-form />
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>

import { MetaInfoMixin } from '@mixins'
import AppointmentForm from '@components/appointment/AppointmentForm'

export default {
  name: 'NewAppointment',
  components: { AppointmentForm },
  mixins: [MetaInfoMixin],
  data () {
    return {}
  },
  mounted () {

  },
  methods: {}
}
</script>
